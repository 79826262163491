export const AR_MESSAGES = {
  en: {
    line1: 'Sorry, the content you are trying to view is not available in your province.',
    line2: 'This content is only available to the following provinces:',
  },
  fr: {
    line1: 'Le contenu que vous essayez de visionner n’est pas disponible dans votre province.',
    line2: 'Le contenu est réservé aux provinces suivantes :',
  },
}

export const AR_REGIONS_DICT = {
  en: {
    ab: 'Alberta',
    bc: 'British Columbia',
    mb: 'Manitoba',
    nb: 'New Brunswick',
    nl: 'Newfoundland',
    nt: 'Northwest Territories',
    ns: 'Nova Scotia',
    nu: 'Nunavut',
    on: 'Ontario',
    pe: 'Prince Edward Island',
    qc: 'Quebec',
    sk: 'Saskatchewan',
    yt: 'Yukon',
  },
  fr: {
    ab: 'Alberta',
    bc: 'Colombie-Britannique',
    mb: 'Manitoba',
    nb: 'Nouveau-Brunswick',
    nl: 'Terre-Neuve',
    nt: 'Territoires du Nord-Ouest',
    ns: 'Nouvelle-Écosse',
    nu: 'Nunavut',
    on: 'Ontario',
    pe: 'Île-du-Prince-Edouard',
    qc: 'Québec',
    sk: 'Saskatchewan',
    yt: 'Yukon',
  },
}
