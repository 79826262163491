import { useState, useEffect } from 'react'
const getStorageValue = <T,>(key: string, defaultValue: T) => {
  let initial: T
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key)
    initial = saved === null ? defaultValue : (JSON.parse(saved) as T)
  }
  return initial
}
const useLocalStorage = <T,>(key: string, defaultValue: T) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })
  useEffect(() => {
    if (key) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }, [key, value])
  return [value, setValue] as const
}
export default useLocalStorage
