import { IGenericPageTemplateFields, IPageDataLayer } from 'contentful-types'
import { getLineOfBusiness } from '../lineOfBusiness/lineOfBusiness'
import { getRootPath, getSubcategoryPath } from './pathFinder'
import { GenericBlogTemplate } from '../../blogBuilder/utils/types/theme'

type Page = {
  name: string
  type: string
  category: string
  brand: string
  siteGenerator: string
  personalizationApi: boolean
  subcategory1?: string
}

export const pageAnalytics = (genericPage: IGenericPageTemplateFields | GenericBlogTemplate) => {
  // TODO: check dataLayer fields looks like they don't exists
  const { dataLayer } = genericPage
  const { slug } = genericPage
  const type = dataLayer && dataLayer.fields.pageType
  const category = dataLayer && dataLayer.fields.category
  const subcategory = dataLayer && dataLayer.fields.subcategory
  const product = dataLayer && dataLayer.fields.dataLayerProduct

  const page: Page = {
    name: slug,
    type: type || 'overview',
    category: category && category.fields.analyticsRef ? category.fields.analyticsRef : slug && getRootPath(slug),
    brand: getLineOfBusiness(slug).pageBrand,
    siteGenerator: 'SiteBuilder',
    personalizationApi: true,
  }

  const dataLayerProduct = {
    name: product && product.fields.name ? product.fields.name : '',
    category: product && product.fields.category ? product.fields.category : '',
    price: product && product.fields.price ? product.fields.price : '',
    sku: product && product.fields.sku ? product.fields.sku : '',
    slug: product && product.fields.slug ? product.fields.slug : '',
  }

  if (subcategory && subcategory.length > 0) {
    subcategory.forEach((s, index) => {
      const subcategoryName = `subcategory${index + 1}`
      page[subcategoryName] = s.fields.analyticsRef
    })
  } else {
    page.subcategory1 = slug && getSubcategoryPath(slug)
  }

  return { page, product: dataLayerProduct }
}
