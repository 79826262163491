import { useEffect } from 'react'
import useLocalStorage from '../siteBuilder/utils/useLocalStorage'

export const CAMPAIGN_CODE = 'cmp'

const getCampaignCodeFromUrl = (parameters: URLSearchParams): string => {
  if (parameters.has(CAMPAIGN_CODE) || parameters.has(CAMPAIGN_CODE.toUpperCase())) {
    return parameters.get(CAMPAIGN_CODE) ?? parameters.get(CAMPAIGN_CODE.toUpperCase())
  }
  return ''
}

const useCampaignCode = () => {
  const [campaignCode, setCampaignCode] = useLocalStorage(CAMPAIGN_CODE, {})

  const daysFromNow = (nDays: number) =>
    new Date(Date.now() + 1000 /* millisec */ * 60 /* sec */ * 60 /* min */ * 24 /* hour */ * nDays)

  useEffect(() => {
    const campaignCodeFromUrl = getCampaignCodeFromUrl(new URLSearchParams(window.location.search))
    if (!campaignCodeFromUrl && Object.keys(campaignCode).length) return
    setCampaignCode({
      campaignCode: campaignCodeFromUrl,
      expires: daysFromNow(30),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCampaignCode])
}

export default useCampaignCode
