import { Language, Province } from '@/siteBuilder/locale'

const domainLine = ''
export const path = ';path=/;expires=Tue, 19 Jan 2038 03:14:07 UTC;SameSite=None;Secure'

export const setLangCookie = (language: Language) => {
  const langLine = 'stackbitLang=' + language + path
  document.cookie = `${langLine}${domainLine}`
}

export const setProvCookie = (province: Lowercase<Province>) => {
  const provLine = 'stackbitProv=' + province + path
  document.cookie = `${provLine}${domainLine}`
}

const changeStackbitLocale = (language: Language, province: Lowercase<Province>) => {
  setLangCookie(language)
  setProvCookie(province)
  window.location.reload()
}

export default changeStackbitLocale
