// strips leading and trailing /'s
export const trimPath = (path: string) => path.replace(/^\/*|\/*$/g, '')

export const getRootPath = (path: string) => trimPath(path).split('/')[0]

export const getSubcategoryPath = (path: string) => {
  const rootRemoved = path.replace(getRootPath(path), '')
  return trimPath(rootRemoved)
}

// Given an object, and a path (field names delimited by period, e.g. 'brand.name')
// Return the value from the object at the location determined by the path
export const getValueByPath = (obj: { [key: string]: any }, path: string) =>
  path.split('.').reduce((childObj, field) => childObj[field], obj)
