import { useEffect } from 'react'
import { Language, Province } from '../siteBuilder/locale'
import changeStackbitLocale from '../siteBuilder/utils/stackbit/changeStackbitLocale'

export type StackbitLocaleChangedEvent = MouseEvent & {
  detail: {
    locale: string
  }
}

type UseStackbitProps = {
  enabled: boolean
}

export const handleStackbitLocaleChanged = (event: StackbitLocaleChangedEvent) => {
  const locale = event.detail.locale !== null ? event.detail.locale : 'en-CA'

  const localeSplit = locale.split('-')

  const language = localeSplit[0]
  let province = ''

  if (localeSplit.length === 3) {
    province = localeSplit[2].toLowerCase()
  }
  changeStackbitLocale(language as Language, province as Lowercase<Province>)
}

export const useStackbit = ({ enabled }: UseStackbitProps) => {
  useEffect(() => {
    if (enabled) {
      window.addEventListener('stackbitLocaleChanged', handleStackbitLocaleChanged)
    }
    return () => {
      window.removeEventListener('stackbitLocaleChanged', handleStackbitLocaleChanged)
    }
  }, [enabled])
}
