import React from 'react'
import { Typography, FlexGrid, Box, ButtonLink, Spacer, StackWrap } from '@telus-uds/components-web'
import { Language } from '../locale'
import { AR_MESSAGES, AR_REGIONS_DICT } from './AvailableRegions.i18n'

export type AvailableRegionsProps = {
  provincesAllowed: ('ab' | 'bc' | 'mb' | 'nb' | 'nl' | 'ns' | 'nt' | 'nu' | 'on' | 'qc' | 'pe' | 'sk' | 'yt')[]
  lang: Language
  slug: string
}

const AvailableRegions = ({ provincesAllowed, lang, slug }: AvailableRegionsProps) => {
  return (
    <FlexGrid>
      <FlexGrid.Row horizontalAlign="center">
        <FlexGrid.Col>
          <Spacer space={11} />
          <Box vertical={4}>
            <Typography variant={{ size: 'h2' }} block>
              {AR_MESSAGES[lang]?.line1}
            </Typography>
            <Spacer space={3} />
            <Typography variant={{ size: 'h3', colour: 'secondary' }} block>
              {AR_MESSAGES[lang]?.line2}
            </Typography>
            <Spacer space={5} />
            <StackWrap
              gap={3}
              space={2}
              tokens={{
                justifyContent: 'center',
              }}
            >
              {provincesAllowed.map((prov) => (
                <ButtonLink
                  key={AR_REGIONS_DICT[lang][prov]}
                  variant={{ priority: 'high' }}
                  href={`/${lang}/${prov}/${slug}`}
                >
                  {AR_REGIONS_DICT[lang][prov]}
                </ButtonLink>
              ))}
            </StackWrap>
          </Box>
          <Spacer space={11} />
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  )
}

export default AvailableRegions
