import React, { useContext } from 'react'
import styled from 'styled-components'

import { FlexGrid, TermsAndConditions, Footnote } from '@telus-uds/components-web'

import { SiteBuilderContext } from '../renderer/context/SiteBuilderContext'

const LegalContainer = styled.div({
  overflow: 'hidden',
})

export default function Legal() {
  const { activeFootnote, dictionary, showFootnote, setShowFootnote, locale } = useContext(SiteBuilderContext)

  let items = []
  if (dictionary?.legal) {
    items = Object.keys(dictionary.legal).map((key) => dictionary.legal[key].value)
  }

  let nonIndexItems = []
  if (dictionary?.nonIndexedLegal) {
    nonIndexItems = Object.keys(dictionary.nonIndexedLegal).map((key) => dictionary.nonIndexedLegal[key].value)
  }

  if (items.length <= 0 && nonIndexItems.length <= 0) {
    return null
  }

  return (
    <LegalContainer>
      <FlexGrid>
        <FlexGrid.Row>
          <FlexGrid.Col>
            <Footnote
              data-testid="uds-footnote"
              copy={locale?.language || 'en'}
              number={activeFootnote.number}
              content={activeFootnote.content}
              onClose={(e, options) => {
                setShowFootnote(false)
                if (options.returnFocus) {
                  activeFootnote?.returnRef?.current?.focus()
                }
              }}
              isOpen={showFootnote}
            />
            <TermsAndConditions
              data-testid="terms-and-conditions"
              copy={locale?.language || 'en'}
              indexedContent={items}
              nonIndexedContent={nonIndexItems}
            />
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </LegalContainer>
  )
}
